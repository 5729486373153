import { gql } from '@apollo/client';

const commonPagesOutput = `
  _id
  projectId
  type
  slug
  createdAt
  active
  content
  contentVersionId
  userId
  order
`;

export const GET_PROPOSAL_PAGES_QUERY = gql`
  query GetProposalPages($params: ProposalPagesParams) {
    getProposalPages(ProposalPagesInput: $params) {
      ${commonPagesOutput}
      stage
      showMinutesLeft
      category
    }
  }
`;

export const GET_MAP_PAGES_QUERY = gql`
  query GetMapPages($params: ProposalPagesParams) {
    getMapPages(ProposalPagesInput: $params) {
      ${commonPagesOutput}
      stage
      showMinutesLeft
      category
    }
  }
`;

export const GET_PLANNING_APPS_PAGES_QUERY = gql`
  query GetPlanningAppPages($params: ProposalPagesParams) {
    getPlanningAppPages(ProposalPagesInput: $params) {
      ${commonPagesOutput}
      stage
      showMinutesLeft
      category
    }
  }
`;

export const GET_PLANNING_APPS_FROM_PROJECT_QUERY = gql`
  query GetPlanningAppsFromProject(
    $projectId: String
    $limit: Int
    $offset: Int
  ) {
    getPlanningAppsFromProject(
      project_id: $projectId
      limit: $limit
      offset: $offset
    ) {
      reference
      glaReference
      name
      stage
      category
      postcode
      shortDesc
      address
      proposal
      receivedDate
      validatedDate
      status
      page_id
    }
  }
`;

export const GET_MILESTONES_PAGES_QUERY = gql`
  query GetMilestonesPages($params: GenericPagesParams) {
    getMilestonesPages(GenericPagesInput: $params) {
      ${commonPagesOutput}
    }
  }
`;

export const GET_TEAM_PAGES_QUERY = gql`
  query GetTeamsPages($params: GenericPagesParams) {
    getTeamsPages(GenericPagesInput: $params) {
      ${commonPagesOutput}
    }
  }
`;

export const GET_HUB_PAGES_QUERY = gql`
  query GetHubPages($params: GenericPagesParams) {
    getHubPages(GenericPagesInput: $params) {
      ${commonPagesOutput}
    }
  }
`;
